import React from 'react'
import { Elements } from 'prismic-richtext'
import linkResolver from './linkResolver'
// import { Link as PrismicLink } from 'prismic-reactjs'
// import { Link } from "gatsby"

// -- Function to add unique key to props
const propsWithUniqueKey = function(props, key) {
  // console.log(key)
  return Object.assign(props || {}, { key })
}

// -- HTML Serializer
// This function will be used to change the way the HTML is loaded
const htmlSerializer = function(type, element, content, children, key) {
  var props = {}

  switch(type) {
    case Elements.heading2: // Heading 2
      props = {className: 'prismic-h2-class'}
      return React.createElement('h2', propsWithUniqueKey(props, key), children);

    case Elements.heading3: // Heading 3
      props = {className: 'prismic-h3-class'}
      return React.createElement('h3', propsWithUniqueKey(props, key), children);

    case Elements.heading4: // Heading 4
      props = {className: 'prismic-h4-class'}
      return React.createElement('h4', propsWithUniqueKey(props, key), children);

    // Add a class to paragraph elements
    case Elements.paragraph:
      props = {className: 'prismic-p-class'}
      return React.createElement('p', propsWithUniqueKey(props, key), children)

    case Elements.list: // Unordered List
      props = {className: 'prismic-ul-class'}
      return React.createElement('ul', propsWithUniqueKey(props, key), children);

    case Elements.oList: // Ordered List
      props = {className: 'prismic-ol-class'}
      return React.createElement('ol', propsWithUniqueKey(props, key), children);

    case Elements.listItem: // Unordered List Item
      props = {className: 'prismic-li-class'}
      return React.createElement('li', propsWithUniqueKey(props, key), children);

    case Elements.oListItem: // Ordered List Item
      props = {className: 'prismic-li-class'}
      return React.createElement('li', propsWithUniqueKey(props, key), children);

    // Don't wrap images in a <p> tag
    case Elements.image:
      props = { src: element.url , alt: element.alt || '' , className: 'prismic-img-class' }
      return React.createElement('img', propsWithUniqueKey(props, key))

    // Add a class to hyperlinks
    case Elements.hyperlink:
      const targetAttr = element.data.target ? { target: element.data.target } : {}
      const relAttr = element.data.target ? { rel: 'noopener' } : {}
      props = Object.assign({
        className: 'prismic-link-class',
        href: element.data.url || linkResolver(element.data)
      }, targetAttr, relAttr)
      return React.createElement('a', propsWithUniqueKey(props, key), children)

    case Elements.embed: // Embed
      props = Object.assign({
        "data-oembed": element.oembed.embed_url,
        "data-oembed-type": element.oembed.type,
        "data-oembed-provider": element.oembed.provider_name,
        className: 'prismic-embed-class',
      }, element.label ? {className: element.label} : {});
      const embedHtml = React.createElement('div', {dangerouslySetInnerHTML: {__html: element.oembed.html}});
      return React.createElement('div', propsWithUniqueKey(props, key), embedHtml);

    // Return null to stick with the default behavior
    default:
      return null
  }
}

export default htmlSerializer