import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PostHeroSection = ({ children, imageData, opacity, height, position }) => {
  const data = useStaticQuery(graphql`
    query PostHeroSectionQuery {
      imageBg: file(relativePath: { eq: "posts/landing-page.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const imageBg = getImage(data.imageBg)

  return (
    <div className="grid relative bg-gray-600 mb-3">
      {/* You can use a GatsbyImage component if the image is dynamic */}
      <GatsbyImage
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          // maxHeight: 600,
          objectFit: "cover",
          opacity: opacity ? opacity : "none",
          width: "100%",
          height: height ? height : "100vh",
        }}
        // This is a presentational image, so the alt should be an empty string
        alt=""
        image={ imageData ? imageData : imageBg }
        formats={["auto", "webp", "avif"]}
        width={1366}
        objectPosition={ position ? position : "center" }
      />
      <div
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",
          // This centers the other elements inside the hero component
          placeItems: "center",
          display: "grid",
        }}
      >
        {/* Any content here will be centered in the component */}
        {children}
      </div>
    </div>
  )
}

PostHeroSection.propTypes = {
  children: PropTypes.node,
  imageData: PropTypes.object,
}

export default PostHeroSection