import React from "react"
import { graphql, Link } from "gatsby"
import { getImage, getSrc } from "gatsby-plugin-image"
import { RichText, Date } from 'prismic-reactjs';
import { Facebook, Linkedin, Twitter } from 'react-social-sharing'
import styled from 'styled-components'

import Layout from "../../components/layout/layout"
import Seo from "../../components/seo"
import htmlSerializer from '../../utils/htmlSerializer';
import PostHeroSection from "../../components/sections/post-hero-section";

const StyledDiv = styled.div`
  .text-shadow {
    text-shadow: 0 3px 3px rgba(0,0,0,.6);
  }
`

const dateStyle = "text-xs mb-8"

const BlogPostTemplateHome = ({ data }) => {
  const siteData = data.site.siteMetadata
  const doc = data.prismicBlogPost
  const imgSrc = getSrc(doc.data.post_image_for_linkedin_share)
  const imgHero = getImage(doc.data.page_hero_background)
  const datePosted = Date(doc.data.date_published)
  const formattedDate = new Intl.DateTimeFormat('en-US',{
    year: "numeric",
    month: "short",
    day: "numeric"
  }).format(datePosted);

  return (
    <Layout>
      <Seo
        title={doc.data.page_title ? doc.data.page_title : null}
        description={doc.data.page_short_description ? doc.data.page_short_description : null}
        image={imgSrc ? imgSrc : null}
      />

      <PostHeroSection imageData={imgHero} height="600px" opacity="0.4">
        <StyledDiv className='w-11/12 xl:max-w-screen-lg  flex flex-col items-center'>
          <p className="text-xl text-white uppercase font-medium text-shadow mb-6"><Link to="/">Home</Link> <span className="ml-3 mr-3"></span> <Link to="/blog">Blog</Link> <span className="ml-3 mr-3"></span> <Link to="/">Know</Link></p>
          <h1 className='font-body font-extrabold text-3xl md:text-4xl xl:text-5xl text-white text-center text-shadow tracking-wider'>
            {doc.data.page_title}
          </h1>
          <p className="text-lg text-white font-medium text-shadow mt-6">By {doc.data.author}</p>
        </StyledDiv>
      </PostHeroSection>

      <div className="py-20">
        <div className="w-11/12 md:max-w-screen-md mx-auto">
          <p className={dateStyle}><span className="mr-2">Published on</span> {formattedDate}</p>
          <RichText
            render={doc.data.post_content.richText}
            htmlSerializer={htmlSerializer}
          />
          <h3 className='font-bold text-center text-xl text-site-green-button border-t-2 border-black mt-12 pt-4'>Share this post:</h3>

          <div className="flex flex-col md:flex-row items-center justify-center mt-2 mx-auto">
            <Linkedin link={`${siteData.siteUrl}/blog/${doc.uid}`} />
            <Facebook link={`${siteData.siteUrl}/blog/${doc.uid}`} />
            <Twitter link={`${siteData.siteUrl}/blog/${doc.uid}`} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPostTemplateHome

export const BlogPostTemplateHomeQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    prismicBlogPost(id: { eq: $id }) {
      id
      uid
      data {
        page_title
        date_published
        author
        page_short_description
        page_hero_background {
          gatsbyImageData(
            width: 1920
          )
        }
        post_content {
          richText
        }
        post_image_for_linkedin_share {
          gatsbyImageData(
            width: 1200
          )
        }
      }
    }
  }
`